$hcto-white: #e8f1f2;
$hcto-black: #0d160b;
$hcto-gray: #374550;
$hcto-blue: #469de6;
$hcto-dark-blue: #03045e;
$hcto-red: #e62c2c;

$theme-colors: (
    "light": $hcto-white,
    "dark": $hcto-gray
);

$font-family-sans-serif: 'Verdana', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
