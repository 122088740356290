@import "variables";
@import "bootstrap-4.3.1/scss/bootstrap";

@each $breakpoint in map-keys($grid-breakpoints) {
    @each $size, $length in $sizes {
        @include media-breakpoint-up($breakpoint) {
            .w-#{$breakpoint}-#{$size} {width: $length !important;}
        }
    }
}

html {
    margin: 0 auto;
}

body {
    background: $hcto-white;
    color: $hcto-black;
}

.navbar__logo {
    height: 3.5em;
    width: auto;
}

// Keep the social links bright.
.navbar-dark .navbar-nav .nav-link__social-icon {
    color: $navbar-dark-hover-color;
}

a.footer__social-icon {
    color: $navbar-dark-hover-color;
}

a:visited.footer__social-icon {
    color: $navbar-dark-hover-color;
}

a:hover.footer__social-icon {
    color: $navbar-dark-hover-color;
}

.bg-hcto-white {
    background: $hcto-white;
    color: $hcto-black;
}

.bg-hcto-white a {
    color: $hcto-blue;
    text-decoration: underline;
}

.bg-hcto-white a:hover {
    color: $hcto-dark-blue;
    text-decoration: underline;
}

.bg-hcto-white a:visited {
    color: $hcto-dark-blue;
    text-decoration: underline;
}

.bg-hcto-gray {
    background: $hcto-gray;
    color: $hcto-white;
}

.bg-hcto-blue {
    background: $hcto-blue;
    color: $hcto-white;
}

.bg-hcto-blue a {
    color: $hcto-black;
    text-decoration: underline;
}

.bg-hcto-blue a:visited {
    color: $hcto-black;
    text-decoration: underline;
}

.bg-hcto-red {
    background: $hcto-red;
    color: $hcto-white;
}

.bg-hcto-black {
    background: $hcto-black;
    color: $hcto-white;
}

.h1__display-heading {
    line-height: 1.2;
    font-size: 2.75em;
}

.h5__display-heading {
    line-height: 1.3;
}

.ul__list-style-none {
    list-style: none;
}

.i__list-icon {
    width: 60px;
}

.footer__copyright-text {
    font-size: 0.75em;
}

.p__display {
    font-size: 1.1em;
    line-height: 1.5;
}

.div__logo-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.div__client-logo {
    width: 35%;
    margin: 15px 10px;
}

.img__client-logo {
    width: 100%;
}

.div__client-headshot {
    width: 150px;
}

.p__client-title {
    font-size: 0.9em;
    margin-bottom: 0;
    //line-height: 1.5;
}

.img__client-headshot {
    width: 100%;
    border-radius: 50%;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up
@media (min-width: 768px) {
    .div__client-logo {
        width: 25%;
        margin: 15px 10px;
    }

    .div__client-testimonial {
        width: 60%;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .div__client-logo {
        width: 25%;
    }

    .div__logo-grid {
      margin: 0 auto;
      width: 90%;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 20px;
    }
}
